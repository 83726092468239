import { createApp } from "vue";

import "@/styles/index.scss"; // global css
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/permission";

import zhCn from "element-plus/es/locale/lang/zh-cn";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

const app = createApp(App);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(store).use(router).mount("#app");
