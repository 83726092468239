/*
 * @Description  : 
 * @Author       : cjz
 * @Date         : 2024-05-19 13:53:55
 * @LastEditors  : cjz
 * @LastEditTime : 2024-07-11 20:06:20
 * @FilePath     : \\environmental-sanitation-frontend\\src\\store\\index.ts
 * Copyright (C) 2024 cjz. All rights reserved.
 */
import dayjs from "dayjs";
import { createStore } from "vuex";

export default createStore({
  state: {
    selfApp: null,
    /** 片区编码 */
    areaCode: '',
    /** t天气模块时间 */
    time: '',
    /** 接口请求日期 */
    date: dayjs().format('YYYY-MM-DD'),
    /** 报警总数 */
    forewarningNum: 0,
    /** 倒计时阈值 */
    cutDownTime: 30,
    /** 车辆列表 */
    vehicleList: [],
    /** 天气模块 */
    weatherInfo: {
      realTimeSwitch: true,
      currentTime: '',
      currentWeather: '',
      sliderTime: 0,
    },
    isAddPath: false,

    peopleList: [] as any[],
    carList: [] as any[],
    carTypeList: [
      { id: 11, name: '干垃圾清运车', remark: 1 },
      { id: 12, name: '湿垃圾清运车', remark: 2 },
      { id: 13, name: '洒水车', remark: 5 },
      { id: 18, name: '可回收垃圾收运车', remark: 7 },
      { id: 19, name: '厨余垃圾收运车', remark: 8 },
      { id: 21, name: '道路巡察车', remark: 9 },
      { id: 33, name: '清扫保洁车', remark: 4 },
      { id: 36, name: '道班冲洗车', remark: 3 },
      { id: 37, name: '吸粪车', remark: 6 },
      { id: 40, name: '自卸式垃圾车', remark: 10 },
    ],
    TaskUnCompleteList: [] as any[],
    SorNList: [
      {
        g: [
          [
            [121.1585468981, 31.1508077691],
            [121.1611419314, 31.1501416631],
            [121.1611724677, 31.1496702925],
            [121.1612229806, 31.1490055112],
            [121.1612916047, 31.1481272757],
            [121.1619727721, 31.1460607507],
            [121.1603575121, 31.1457647819],
            [121.1590005555, 31.1454949072],
            [121.1596758848, 31.1371794379],
            [121.1358514577, 31.1372420316],
            [121.1360895104, 31.1378958968],
            [121.1363782915, 31.1386871643],
            [121.1366865945, 31.1395654455],
            [121.1373966367, 31.1420419147],
            [121.1376885530, 31.1434183193],
            [121.1380015706, 31.1454434189],
            [121.1385570268, 31.1500977168],
            [121.1390167034, 31.1543874926],
            [121.1470996450, 31.1531656893],
            [121.1516422977, 31.1523555557],
            [121.1547913731, 31.1517713741],
            [121.1568572408, 31.1512424829],
          ],
        ],
        key: 'SRange',
        color: '#e6d7a1 ',
        area_code: '00001',
        centerPoint: [
          121.14912228284305,
          31.144849803243503
        ],
        name: "南片区",
        // finishNum: '87%',
        // doneNum: '100%'
      },
      {
        g: [
          [
            [121.1471854645, 31.1684862553],
            [121.1491671980, 31.1685434932],
            [121.1527741405, 31.1687057312],
            [121.1572195630, 31.1690096081],
            [121.1573698979, 31.1665744980],
            [121.1575715449, 31.1637684634],
            [121.1580182656, 31.1573129598],
            [121.1585481281, 31.1508077016],
            [121.1569120393, 31.1512294565],
            [121.1547869743, 31.1517789220],
            [121.1494018602, 31.1527586576],
            [121.1470808766, 31.1531712648],
            [121.1390161792, 31.1543880672],
            [121.1392524744, 31.1564040341],
            [121.1409232889, 31.1561571174],
            [121.1435417365, 31.1557933164],
            [121.1469869491, 31.1557528980],
            [121.1471365013, 31.1557537873],
            [121.1471237851, 31.1684862127],
          ],
        ],
        key: 'NRange', //北
        name: "北片区",
        color: '0f7fef50',
        area_code: '00002',
        centerPoint: [
          121.15104078113319,
          31.15820532553481
        ]
      },
    ],
    leftCurrentSelected: '',
    buttonDisable: false,
    currentPathCar: null
  },
  getters: {
    carListGetter: (state) => state.carList,
    peopleListGetter: (state) => state.peopleList,
    isDisAbleGetter: (state) => state.peopleList,
    currentPathCarGetter: (state) => state.currentPathCar,
  },
  mutations: {
    SET_BASE_APP(state, data) {
      state.selfApp = data
    },
    SET_AREA_CODE(state, data) {
      state.areaCode = data
    },
    SET_WARNING_NUM(state, data) {
      state.forewarningNum = data
    },
    SET_PEOPLELIST(state, data) {
      state.peopleList = data
    },
    SET_CARLIST(state, data) {
      state.carList = data
    },
    SET_WEATHER_INFO(state, data) {
      state.weatherInfo = data
    },

    SET_TIME(state, data) {
      state.time = data
    },
    setTaskUnCompleteList(state, data) {
      state.TaskUnCompleteList = data
    },
    setIsAddPath(state, data) {
      state.isAddPath = data
    },
    setVehicleList(state, data) {
      state.vehicleList = data
    },
    setLeftCurrentSelected(state, data) {
      state.leftCurrentSelected = data
    },
    setCurrentPathCar(state, data) {
      state.currentPathCar = data
    },
    setButtonDisable(state, data) {
      state.buttonDisable = data
    }
  },
  actions: {},
  modules: {},
});
