/*
 * @Description  : 
 * @Author       : cjz
 * @Date         : 2024-06-19 23:36:23
 * @LastEditors  : cjz
 * @LastEditTime : 2024-07-04 00:46:55
 * @FilePath     : \\frontend\\environmental-sanitation-frontend\\src\\router\\index.ts
 * Copyright (C) 2024 cjz. All rights reserved.
 */
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/bigScreen",
  },
  {
    path: "/bigScreen",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "BigScreen" */ "../views/BigScreen.vue"),
  },

  {
    path: "/Demo",
    name: "Demo",
    component: () =>
      import(/* webpackChunkName: "BigScreen" */ "../views/Demo.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
