<!--
 * @Description  : 
 * @Author       : cjz
 * @Date         : 2024-05-10 21:29:17
 * @LastEditors  : cjz
 * @LastEditTime : 2024-06-05 22:20:58
 * @FilePath     : \\frontend\\environmental-sanitation-frontend\\src\\App.vue
 * Copyright (C) 2024 cjz. All rights reserved.
-->
<template>
  <div class="ctn">
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  components: {
  },
  props: {
    title: String,
  },
  setup() {
    return {};
  },
});
</script>


<style lang="scss" scoped>
// .ctn {
//   width: 1920px;
//   height: 1080px;
// }
</style>
